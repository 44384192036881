import React, {Component} from "react";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import "./ModalProduct.scss";

class ModalProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null, 
      confKey: "", 
      confValue: "", 
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  onInputChange = event => {
    const { name, value } = event.target;
    //console.log(".....cwk761-100 ModalProduct name=",name, ",value=",value);
    this.setState({ [name]: value });  
  };

  handleChange(event) {    
    this.setState({value: event.target.value});  
  }

  handleSubmit(event) {
    console.log(".....cwk761-222 ModalProduct handleSubmit this.state=",this.state);
    this.props.onAsyncInsertAShoppingProduct(this.state);
    this.props.onAsyncQueryShoppingProduct();
    event.preventDefault();
    this.props.onParentSubmit();
  }

  handleCancel(event) {
    console.log(".....cwk761-222 ModalProduct handleSubmit this.state=",this.state);
    //alert('submitted: ' + this.state.value);
    event.preventDefault();
    this.props.onParentSubmit();
  }

  render() {
    //console.log("....cwk101-111 this.props=", this.props);
    return (
      <div className="layout">
        {/* <form onSubmit={this.handleSubmit}> */}
        <form >
            <div className="form-group">
              <label>confKey </label>
              <input 
                type="text" 
                name="confKey" 
                value={this.state.confKey} 
                onChange={this.onInputChange} 
              />
            </div>
            <div className="form-group">
              <label>confValue </label>
              <input
                type="text"
                name="confValue"
                value={this.state.confValue}
                onChange={this.onInputChange}
              />
            </div>
            <div className="form-group form-group--actions">
              <button className="primary-btn" onClick={this.handleSubmit}>Create</button>
              <button className="cancel-btn" onClick={this.handleSubmit}> Cancel </button>
            </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAsyncInsertAShoppingProduct: (parms) => dispatch(actions.asyncInsertAShoppingProduct(parms)),
    onAsyncQueryShoppingProduct:    ()       => dispatch(actions.asyncQueryShoppingProduct()), 

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalProduct);
