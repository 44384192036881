import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
//import { Modal, Button } from 'react-bootstrap'
//import 'bootstrap/dist/css/bootstrap.min.css';

// Styles
import "./Setting.scss";

// Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DataTable from "../../components/Setting/DatatableSetting";
//import CreateSetting from "../../components/Setting/CreateSetting";
//import UpdateSetting from "../../components/Setting/UpdateSetting";
//import Modal from "../../components/Modal";
import Search from "../../components/Search";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";
import MySwal from "../../index";
import ModalSetting from './ModalSetting';

Modal.setAppElement("#root");

const pageSize = parseInt(process.env.REACT_APP_TABLE_PAGESIZE);   /* env variable */
const thePageSize = parseInt(process.env.REACT_APP_TABLE_PAGESIZE);

class SettingPage extends Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
      show: false,
      settingsSelected: [],
      currentPage: 0,
      offset: 0,
      pageCount: 0,
      pageSize:0,
    };

    this.actionCreateSetting = this.actionCreateSetting.bind(this);    
    this.deleteRow = this.deleteRow.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.sorting   = this.sorting.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  
  componentDidMount() {
    var eleFirst = (this.state.currentPage-1)*thePageSize;
    var eleLast  = eleFirst + thePageSize;
    this.setState( { 
      settingsSelected: this.props.mappedSettings.slice(eleFirst,eleLast),
      pageSize: thePageSize
    });
    //console.log(".....cwk020-995 componentDidMount() this.state.settingsSelected=", this.state.settingsSelected);
  }

  componentDidUpdate() {
    //console.log(".....cwk020-993-01 Setting componentDidUpdate() this.props.Settings=",this.props.Settings);
    if ( this.state.isLoaded ) return;
    const quotient  = ~~(this.props.mappedSettings.length/thePageSize);
    const remainder = this.props.mappedSettings.length % thePageSize;   
    var thePageCount = quotient;
    if ( remainder >0 ) {
      thePageCount = thePageCount +1;
    }
    //console.log(".....cwk020-993-02 Setting componentDidUpdate() this.props.Settings=",this.props.Settings);
    this.setState( { 
      isLoaded: true,
      pageCount: thePageCount,
      settingsSelected: this.props.mappedSettings.slice(0,thePageSize)
    });
  }

  actionCreateSetting = () => {
    //console.log(".....cwk991-100 actionCreateSetting ModalTest01.state=", ModalTest01.props);
    this.hideModal();
  }

  // Delete Setting
  deleteRow = (setting) => {
    console.log(".....cwk991-200 deleteRow setting=",setting);
    const {id, confKey, confValue} = setting;
    this.props.onAsyncDeleteASetting(setting);
    this.props.onAsyncQuerySetting();
    MySwal.fire({
        icon: "success",
        title: "setting id="+id+" "+setting.confKey+" "+setting.confValue+" has been deleted successfully."
    });

  };

  // update Setting
  updateRow = (setting) => {
    //console.log(".....cwk991-300 updateRow setting=",setting);
    this.props.onAsyncUpdateASetting(setting);
    this.props.onAsyncQuerySetting();
    MySwal.fire({
      icon: "success",
      title: "setting "+setting.confKey+" "+setting.confValue+" has been updated successfully."
    });
  };

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    //console.log(".....cwk991-777 Setting.js hideModal");
    this.setState({ show: false });
  };

  // Sorting
  sorting = key => {
    //console.log(".....cwk001-100 key=",key);
    let ordered = this.state.settingsSelected.sort( (a, b) => (a[key] > b[key] ? 1 : -1) );
    //console.log(".....cwk001-200 this.state.settingsSelected=",this.state.settingsSelected);
    //console.log(".....cwk001-210 ordered=",ordered);
    this.setState({
      settingsSelected: ordered,
    })
  };

  
  
  render() {
    //console.log(".....cwk020-997 render() this.state.settingsSelected=", this.state.settingsSelected);
    //console.log(".....cwk020-999 render() this.props.Settings       =", this.props.Settings);
    /*
    if ( this.props.SettingIsLoading || (this.state.settingsSelected.length==0) ) {
      console.log(".....cwk020-999 render() this.props.SettingIsLoading=", this.props.SettingIsLoading);
      return <p>Loading</p>
    }
    */
    //console.log(".....cwk333-200 render() this.state.settingsSelected=",this.state.settingsSelected);
    return (
      <div className="app">
        <Header />
        <main className="content">
          <div className="content-wrapper">
            <div className="toolbar">
              <button className="jamsmartCreate-btn" onClick={this.showModal}>Create Setting</button>
              <Modal
                isOpen={this.state.show}
                onRequestClose={this.toggleModal}
                contentLabel="My dialog"
                className="layout"
              >
                <div>Create Setting</div>
                <div >
                  <ModalSetting onParentSubmit={this.hideModal}/>
                </div>
              </Modal>
            </div>
            <DataTable 
              //tableSettings={this.state.settingsSelected} 
              deleteRow={this.deleteRow}
              updateRow={this.updateRow}
            />
          </div>
        </main>
        <Footer />
      </div>
    )
  }
};

/*
  Wait for redux action to finish dispatching
*/
SettingPage.propTypes = {
  Settings: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  return {
    mappedSettings:         state.setting.reduxSettings,
    SettingIsLoading: state.setting.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAsyncDeleteASetting: (setting) => dispatch(actions.asyncDeleteASetting(setting)),
    onAsyncUpdateASetting: (setting) => dispatch(actions.asyncUpdateASetting(setting)),
    onAsyncQuerySetting:    ()       => dispatch(actions.asyncQuerySetting()), 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingPage);
