import * as actionTypes from './actionTypes';
import axios from '../../axios';


const apiURL = process.env.REACT_APP_REQRES_API;

export const loadingTuser = () => {
  return {
    type: actionTypes.TUSERS_LOADING,
  }
};

export const queryTuserSuccess = data => {
    return {
      type: actionTypes.TUSERS_QUERY_SUCCESS,
      data
    }
};
  
export const insertTuserSuccess = data => {
  return {
    type: actionTypes.TUSERS_INSERT_A_TUSER_SUCCESS,
    data
  }
};

export const deleteTuserSuccess = data => {
  return {
    type: actionTypes.TUSERS_DELETE_A_TUSER_SUCCESS,
    data
  }
};

export const updateTuserSuccess = data => {
  return {
    type: actionTypes.TUSERS_UPDATE_A_TUSER_SUCCESS,
    data
  }
};

export const asyncQueryTuser = () => {
  return dispatch => {
    dispatch(loadingTuser());
    axios.get(apiURL+"/getTusers/").then(({ data }) => {
          dispatch(queryTuserSuccess(data));
        })
        .catch(err => {
            console.log("....cwk123-100 Tuser action err=",err)
        });
  };
};

export const asyncInsertATuser = (params) => {
  return dispatch => {
    //console.log(".....cwk666-100 src/store/actions/tuserActions.js asyncInsertATuser params=",params)
    axios.post(apiURL+"/postATuser/",{
      params: {
        first_name: params.first_name,
        last_name: params.last_name,
        email: params.email
      }  
    }).then(({ data }) => {
          dispatch(insertTuserSuccess(data));
        })
        .catch(err => {
            console.log("....cwk123-300 Tuser insert action err=",err)
        });
  };
};

export const asyncDeleteATuser = (params) => {
  return dispatch => {
    //console.log(".....cwk666-100 src/store/actions/tuserActions.js asyncDeleteATuser params=",params)
    axios.delete(apiURL+"/delATuser/"+params.id, {
      params: {
        id: params.id
      }  
    }).then(({ data }) => {
          dispatch(deleteTuserSuccess(data));
        })
        .catch(err => {
            console.log("....cwk123-300 Tuser delete action err=",err)
        });
  };
};

export const asyncUpdateATuser = (params) => {
  return dispatch => {
    console.log(".....cwk666-100 src/store/actions/tuserActions.js asyncUpdateATuser params=",params)
    const { id, avatar, first_name, last_name, email } = params;

    axios.put(apiURL+"/putATuser/", {
      params: {
        id: id,
        avatar: avatar,
        first_name: first_name,
        last_name: last_name,
        email: email
      }  
    }).then(({ data }) => {
          dispatch(updateTuserSuccess(data));
        })
        .catch(err => {
            console.log("....cwk123-300 Tuser update action err=",err)
        });
  };
};
