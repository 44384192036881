import React, { Component, Fragment } from 'react';
import ReactDOM from "react-dom";
import Sidebar from "./components/Navigation/Sidebar";
import { Switch, Route, Redirect, withRouter, BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Home  from "./containers/Home/Home";
import About from "./containers/About/About";
import Contact from "./containers/Contact/Contact";
import Tuser from "./containers/Tuser/Tuser";
import * as actions from './store/actions';
import Setting from "./containers/Setting/Setting";
import Imagefile from "./containers/Imagefiles/Imagefiles";
import Product from "./containers/Products/Product";

//import "./styles.css";

class App extends Component {
    state = { isLoaded: false };
    componentDidMount() { 
/* 
        console.log(".....cwk001-113 process.env.REACT_APP_NODE_ENV=",process.env.REACT_APP_NODE_ENV);
        console.log(".....cwk001-123 process.env.REACT_APP_REQRES_API=",process.env.REACT_APP_REQRES_API);     
        console.log(".....cwk001-133 process.env.REACT_APP_API_URL_GET=",process.env.REACT_APP_API_URL_GET);
        console.log(".....cwk001-143 process.env.REACT_APP_API_XXXXX=",process.env.REACT_APP_API_XXXXX);
        console.log(".....cwk001-153 process.env.REACT_APP_API_URL=",process.env.REACT_APP_API_URL);
        console.log(".....cwk001-163 process.env.REACT_APP_API_IMAGE_URL=",process.env.REACT_APP_API_IMAGE_URL);
        console.log(".....cwk001-173 process.env.REACT_APP_API_SHOP=",process.env.REACT_APP_API_SHOP);
*/
        this.props.onFetchTusers();    
        this.props.onFetchSettings();    
        this.props.onFetchImagefiles();    
        this.props.onFetchShoppingProduct();    
    };
  
    render() {
        return (
            <BrowserRouter>
            <div className="App">
                <Sidebar />
                    <div id="page-wrap">
                </div>
            </div>
            <Route path="/Home" component={Home} />
            <Route path="/About" component={About} />
            <Route path="/Contact" component={Contact} />
            <Route path="/Tuser" component={Tuser} />
            <Route path="/Setting" component={Setting} />
            <Route path="/Product" component={Product} />
            <Route path="/Imagefile" component={Imagefile} />
            </BrowserRouter>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
  };
  
const mapDispatchToProps = dispatch => {
    return {
        onFetchTusers:          () => dispatch(actions.asyncQueryTuser()),
        onFetchSettings:        () => dispatch(actions.asyncQuerySetting()),
        onFetchImagefiles:      () => dispatch(actions.asyncQueryImagefile()),
        onFetchShoppingProduct: () => dispatch(actions.asyncQueryShoppingProduct()),
    };
  };
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));