// TUSER REDUCER
export const TUSERS_SET                         = 'TUSERS_SET'; 
export const TUSERS_CREATE                      = 'TUSERS_CREATE'; 
export const TUSERS_DELETE                      = 'TUSERS_DELETE'; 
export const TUSERS_UPDATE                      = 'TUSERS_UPDATE'; 
export const TUSERS_QUERY_SUCCESS               = 'TUSERS_QUERY_SUCCESS'; 
export const TUSERS_LOADING                     = 'TUSERS_LOADING';
export const TUSERS_INSERT_A_TUSER_SUCCESS      = 'TUSERS_INSERT_A_TUSER_SUCCESS';
export const TUSERS_DELETE_A_TUSER_SUCCESS      = 'TUSERS_DELETE_A_TUSER_SUCCESS';
export const TUSERS_UPDATE_A_TUSER_SUCCESS      = 'TUSERS_UPDATE_A_TUSER_SUCCESS';

// SETTINGS REDUCER
export const SETTINGS_SET                       = 'SETTINGS_SET'; 
export const SETTINGS_CREATE                    = 'SETTINGS_CREATE'; 
export const SETTINGS_DELETE                    = 'SETTINGS_DELETE'; 
export const SETTINGS_UPDATE                    = 'SETTINGS_UPDATE'; 
export const SETTINGS_QUERY_SUCCESS             = 'SETTINGS_QUERY_SUCCESS'; 
export const SETTINGS_LOADING                   = 'SETTINGS_LOADING';
export const SETTINGS_INSERT_A_SETTING_SUCCESS  = 'SETTINGS_INSERT_A_SETTING_SUCCESS';
export const SETTINGS_DELETE_A_SETTING_SUCCESS  = 'SETTINGS_DELETE_A_SETTING_SUCCESS';
export const SETTINGS_UPDATE_A_SETTING_SUCCESS  = 'SETTINGS_UPDATE_A_SETTING_SUCCESS';

// IMAGES REDUCER
export const IMAGES_SET                         = 'IMAGES_SET'; 
export const IMAGES_CREATE                      = 'IMAGES_CREATE'; 
export const IMAGES_DELETE                      = 'IMAGES_DELETE'; 
export const IMAGES_UPDATE                      = 'IMAGES_UPDATE'; 
export const IMAGES_QUERY_SUCCESS               = 'IMAGES_QUERY_SUCCESS'; 
export const IMAGES_LOADING                     = 'IMAGES_LOADING';
export const IMAGES_INSERT_AN_IMAGE_SUCCESS     = 'IMAGES_INSERT_AN_IMAGE_SUCCESS';
export const IMAGES_DELETE_AN_IMAGE_SUCCESS     = 'IMAGES_DELETE_AN_IMAGE_SUCCESS';
export const IMAGES_UPDATE_AN_IMAGE_SUCCESS     = 'IMAGES_UPDATE_AN_IMAGE_SUCCESS';

// SHOPPINGPRODUCT REDUCER
export const SHOPPINGPRODUCTS_SET                            = 'SHOPPINGPRODUCTS_SET'; 
export const SHOPPINGPRODUCTS_CREATE                         = 'SHOPPINGPRODUCTS_CREATE'; 
export const SHOPPINGPRODUCTS_DELETE                         = 'SHOPPINGPRODUCTS_DELETE'; 
export const SHOPPINGPRODUCTS_UPDATE                         = 'SHOPPINGPRODUCTS_UPDATE'; 
export const SHOPPINGPRODUCTS_QUERY_SUCCESS                  = 'SHOPPINGPRODUCTS_QUERY_SUCCESS'; 
export const SHOPPINGPRODUCTS_LOADING                        = 'SHOPPINGPRODUCTS_LOADING';
export const SHOPPINGPRODUCTS_INSERT_A_PRODUCT_SUCCESS       = 'SHOPPINGPRODUCTS_INSERT_A_PRODUCT_SUCCESS';
export const SHOPPINGPRODUCTS_DELETE_A_PRODUCT_SUCCESS       = 'SHOPPINGPRODUCTS_DELETE_A_PRODUCT_SUCCESS';
export const SHOPPINGPRODUCTS_UPDATE_A_PRODUCT_SUCCESS       = 'SHOPPINGPRODUCTS_UPDATE_A_PRODUCT_SUCCESS';


// ORDER REDUCER
// handle form submit
export const PURCHASE_INIT = 'PURCHASE_INIT';
export const PURCHASE_ORDER_SUCCESS = 'PURCHASE_ORDER_SUCCESS';
export const PURCHASE_ORDER_FAIL = 'PURCHASE_ORDER_FAIL';
export const PURCHASE_ORDER_START = 'PURCHASE_ORDER_START';

// handle orders list
export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL';

// AUTH REDUCER
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

// HOMEPAGE REDUCER
export const QUERY_HOMEPAGE_SUCCESS = 'QUERY_HOMEPAGE_SUCCESS'; 
