import React from 'react';
import './Contact.scss';
import ScrollToTopOnMount from '../../shared/ScrollToTopOnMount';
import Header from "../../components/Header";
import Footer from "../../components/Footer";



const contact = () => (
  <>
    <Header/>
    <ScrollToTopOnMount />
    <div className="contact-container">
      <h2 className="main-title">How can we help?</h2>
      <p className="main-info">We’d love to help get you connected to one of our services. Feel free to fill out the contact form to receive a reply, or contact us via phone.</p>
      <h3 className="title">Phone number:</h3>
      <p>1-123-456-789 (fake connection)</p>
      <h3 className="title">Work hours:</h3>
      <p>Monday - Friday: 9.00 - 16.00</p>
      <p>Saturday - SUnday: 10.00 - 12.00</p>
    </div>
    <Footer />
  </>
);

export default contact;
