import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import './Sidebar.scss';

export default class Sidebar extends React.Component {
  state = {
    menuOpen: false
  };

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  render() {
    return (
      <Menu
        isOpen={this.state.menuOpen}
        onStateChange={state => this.handleStateChange(state)}
      >
        <Link onClick={() => this.closeMenu()} className="menu-item" to="/Home">
          Home
        </Link>
        <Link onClick={() => this.closeMenu()} className="menu-item" to="/About" >
          About
        </Link>
        <Link onClick={() => this.closeMenu()} className="menu-item" to="/Contact" >
          Contact
        </Link>
        <Link onClick={() => this.closeMenu()} className="menu-item" to="/Tuser" >
          Tuser
        </Link>
        <Link onClick={() => this.closeMenu()} className="menu-item" to="/Setting" >
          Settings
        </Link>
        <Link onClick={() => this.closeMenu()} className="menu-item" to="/Product" >
          Products
        </Link>
        <Link onClick={() => this.closeMenu()} className="menu-item" to="/Imagefile" >
          Image Files
        </Link>
      </Menu>
    );
  }
}