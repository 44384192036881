import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import PropTypes from 'prop-types';
import Modal from "react-modal";

// Styles
import "./Imagefiles.scss";

// Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DataTable from "../../components/Imagefile/DatatableImagefile";
import MySwal from "../../index";
import ModalImagefile from './ModalImagefile';

Modal.setAppElement("#root");

const pageSize = parseInt(process.env.REACT_APP_TABLE_PAGESIZE);   /* env variable */
const thePageSize = parseInt(process.env.REACT_APP_TABLE_PAGESIZE);

class ImagefilePage extends Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
      show: false,
      Selected: [],
      currentPage: 0,
      offset: 0,
      pageCount: 0,
      pageSize:0,
    };

    this.actionCreateSetting = this.actionCreateSetting.bind(this);    
    this.deleteRow = this.deleteRow.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.sorting   = this.sorting.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  
  componentDidMount() {
    var eleFirst = (this.state.currentPage-1)*thePageSize;
    var eleLast  = eleFirst + thePageSize;
    this.setState( { 
      settingsSelected: this.props.mappedSettings.slice(eleFirst,eleLast),
      pageSize: thePageSize
    });
    //console.log(".....cwk020-995 componentDidMount() this.state.settingsSelected=", this.state.settingsSelected);
  }

  componentDidUpdate() {
    //console.log(".....cwk020-993-01 Imagefile componentDidUpdate() this.props.Settings=",this.props.Settings);
    if ( this.state.isLoaded ) return;
    const quotient  = ~~(this.props.mappedSettings.length/thePageSize);
    const remainder = this.props.mappedSettings.length % thePageSize;   
    var thePageCount = quotient;
    if ( remainder >0 ) {
      thePageCount = thePageCount +1;
    }
    //console.log(".....cwk020-993-02 Imagefile componentDidUpdate() this.props.Settings=",this.props.Settings);
    this.setState( { 
      isLoaded: true,
      pageCount: thePageCount,
      settingsSelected: this.props.mappedSettings.slice(0,thePageSize)
    });
  }

  actionCreateSetting = () => {
    //console.log(".....cwk991-100 actionCreateSetting ModalTest01.state=", ModalTest01.props);
    this.hideModal();
  }

  // Delete Imagefile
  deleteRow = (imagefile) => {
    console.log(".....cwk991-200 deleteRow imagefile=",imagefile);
    this.props.onAsyncDeleteAnImageFile(imagefile);
    this.props.onAsyncQueryImagefile();
    MySwal.fire({
        icon: "success",
        title: "Image file "+imagefile.id+" has been deleted successfully."
    });

  };

  // update Imagefile
  updateRow = (imagefile) => {
    //console.log(".....cwk991-300 updateRow imagefile=",imagefile);
    this.props.onAsyncUpdateAnImageFile(imagefile);
    this.props.onAsyncQueryImagefile();
    MySwal.fire({
      icon: "success",
      title: "Image file "+imagefile.id+" has been updated successfully."
    });
  };

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    //console.log(".....cwk991-777 Imagefile.js hideModal");
    this.setState({ show: false });
  };

  // Sorting
  sorting = key => {
    //console.log(".....cwk001-100 key=",key);
    let ordered = this.state.settingsSelected.sort( (a, b) => (a[key] > b[key] ? 1 : -1) );
    //console.log(".....cwk001-200 this.state.settingsSelected=",this.state.settingsSelected);
    //console.log(".....cwk001-210 ordered=",ordered);
    this.setState({
      settingsSelected: ordered,
    })
  };

  
  
  render() {
    //console.log(".....cwk020-997 render() this.state.settingsSelected=", this.state.settingsSelected);
    //console.log(".....cwk020-999 render() this.props.Settings       =", this.props.Settings);
    /*
    if ( this.props.SettingIsLoading || (this.state.settingsSelected.length==0) ) {
      console.log(".....cwk020-999 render() this.props.SettingIsLoading=", this.props.SettingIsLoading);
      return <p>Loading</p>
    }
    */
    //console.log(".....cwk333-200 render() this.state.settingsSelected=",this.state.settingsSelected);
    return (
      <div className="app">
        <Header />
        <main className="content">
          <div className="content-wrapper">
            <div className="toolbar">
              <button className="jamsmartCreate-btn" onClick={this.showModal}>Upload Image File</button>
              <Modal
                isOpen={this.state.show}
                onRequestClose={this.toggleModal}
                contentLabel="My dialog"
                className="layout"
              >
                <div>Create Imagefile</div>
                <div >
                  <ModalImagefile onParentSubmit={this.hideModal}/>
                </div>
              </Modal>
            </div>
            <DataTable 
              //tableSettings={this.state.settingsSelected} 
              deleteRow={this.deleteRow}
              updateRow={this.updateRow}
            />
          </div>
        </main>
        <Footer />
      </div>
    )
  }
};

/*
  Wait for redux action to finish dispatching
*/
ImagefilePage.propTypes = {
  Imagefiles: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  return {
    mappedSettings:           state.imageFile.reduxImagefiles,
    ImagefileIsLoading:       state.imageFile.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAsyncDeleteAnImageFile: (imagefile) => dispatch(actions.asyncDeleteAnImagefile(imagefile)),
    onAsyncUpdateAnImageFile: (imagefile) => dispatch(actions.asyncUpdateAnImagefile(imagefile)),
    onAsyncQueryImagefile:    () => dispatch(actions.asyncQueryImagefile()), 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImagefilePage);
