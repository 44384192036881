import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import * as serviceWorker from './serviceWorker';
import App from './App';
//import "./styles.css";
// Sweet Alert 2
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// Store
import tuserReducer from "./store/reducers/tuserReducer";
import settingReducer from "./store/reducers/settingReducer";
import imageFileReducer from "./store/reducers/imagefileReducer";
import productReducer from "./store/reducers/productReducer";

const MySwal = withReactContent(Swal);
export default MySwal;

const rootReducer = combineReducers({
  tuser:            tuserReducer,
  setting:          settingReducer,
  imageFile:        imageFileReducer,
  product:          productReducer,
});

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk)
  )
);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
