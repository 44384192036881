import * as actionTypes from './actionTypes';
import axios from '../../axios';


const apiURL = process.env.REACT_APP_REQRES_API;

export const loadingImagefile = () => {
  return {
    type: actionTypes.IMAGES_LOADING,
  }
};

export const queryImagefileSuccess = data => {
    return {
      type: actionTypes.IMAGES_QUERY_SUCCESS,
      data
    }
};
  
export const insertImagefileSuccess = data => {
  return {
    type: actionTypes.IMAGES_INSERT_AN_IMAGE_SUCCESS,
    data
  }
};

export const deleteImagefileSuccess = data => {
  return {
    type: actionTypes.IMAGES_DELETE_AN_IMAGE_SUCCESS,
    data
  }
};

export const updateImagefileSuccess = data => {
  return {
    type: actionTypes.IMAGES_UPDATE_AN_IMAGE_SUCCESS,
    data
  }
};

export const asyncQueryImagefile = () => {
  return dispatch => {
    dispatch(loadingImagefile());
    axios.get(apiURL+"/getImageFiles/").then(({ data }) => {
          dispatch(queryImagefileSuccess(data));
        })
        .catch(err => {
            console.log("....cwk123-100 Imagefile action err=",err)
        });
  };
};

export const asyncInsertAnImagefile = (params) => {
  return dispatch => {
    console.log(".....cwk666-100 src/store/actions/tuserActions.js asyncInsertAImagefile params=",params)
    const formData = new FormData();        
    const {file, id, imagename, comment, shoppingsite} = params;
    formData.append('filename', file.name);   //append the values with key, value pair
    formData.append('shoppingsite', shoppingsite);
    formData.append('comment', comment);
    formData.append('file', file); // appending file
    const url = apiURL + "/postAnImageFile/";
    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
    .then(({ data }) => {
          dispatch(insertImagefileSuccess(data));
        })
        .catch(err => {
            console.log("....cwk123-300 Imagefile insert action err=",err)
        });
    };
};

export const asyncDeleteAnImagefile = (params) => {
  return dispatch => {
    console.log(".....cwk666-100 src/store/actions/tuserActions.js asyncDeleteAImagefile params=",params)
    axios.delete(apiURL+"/delAnImageFile/"+params.id, {
      params: {
        id: params.id
      }  
    }).then(({ data }) => {
          dispatch(deleteImagefileSuccess(data));
        })
        .catch(err => {
            console.log("....cwk123-300 Imagefile delete action err=",err)
        });
  };
};

export const asyncUpdateAnImagefile = (params) => {
  return dispatch => {
    console.log(".....cwk666-101 src/store/actions/tuserActions.js asyncUpdateAImagefile params=",params)
    //const { id, imagename, comment } = params;

    axios.put(apiURL+"/putAnImageFile/", {
      params: {
        id: params.id,
        imagename: params.imagename,
        comment: params.comment,
      }  
    }).then(({ data }) => {
          dispatch(updateImagefileSuccess(data));
        })
        .catch(err => {
            console.log("....cwk123-301 Imagefile update action err=",err)
        });
  };
};
