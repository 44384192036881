import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  reduxImagefiles: [],
};

const setLoadingImagefileStatus = (state, action) => {
  return {
    ...state,
    isLoading: true
  }
}

const queryImagefileSuccess = (state, action) => {

  return {
    ...state,
    isLoading: false,
    reduxImagefiles: action.data,
  }
}

const insertImagefileSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    //reduxImagefiles: action.data
  }
}

const deleteImagefileSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    //reduxImagefiles: theList
  }
}

const updateImagefileSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    //reduxImagefiles: action.data
  }
}

const imagefileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.IMAGES_QUERY_SUCCESS:
      return queryImagefileSuccess(state, action); 
    case actionTypes.IMAGES_LOADING:
      return setLoadingImagefileStatus(state, action); 
    case actionTypes.IMAGES_INSERT_AN_IMAGE_SUCCESS:
      return insertImagefileSuccess(state, action); 
    case actionTypes.IMAGES_DELETE_AN_IMAGE_SUCCESS:
      return deleteImagefileSuccess(state, action); 
    case actionTypes.IMAGES_UPDATE_AN_IMAGE_SUCCESS:
      return updateImagefileSuccess(state, action); 
        
    default:
      return state;
  }
}

export default imagefileReducer;