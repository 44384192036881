import React, {Component} from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import "./style.scss";
import Modal from "react-modal";
import ModalUpdate from './ModalUpdate';
import ReactPaginate from 'react-paginate';

// Images
import PlaceholderImg from "../img/placeholder-user.jpg";
import SortIcon from "../img/sort-icon.png";

const pageSize = parseInt(process.env.REACT_APP_TABLE_PAGESIZE);   /* env variable */
const thePageSize = parseInt(process.env.REACT_APP_TABLE_PAGESIZE);

class tableImagefile extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        imagefileCurrent: [],
        imagefilesSelected: [],
        currentPage: 0,
        offset: 0,
        pageCount: 0,
        pageSize:0,
      };
      
    this.onInputChange = this.onInputChange.bind(this);
    this.onSortChange  = this.onSortChange.bind(this);
    this.handlePageClick  = this.handlePageClick.bind(this);
    this.showModal     = this.showModal.bind(this);
    this.hideModal     = this.hideModal.bind(this);
  }

  componentDidMount() {
    //console.log(".....cwk999-991 src/components/Tuser/DatatableImagefile/index.js componentDidMount this.props.tableUsers=",this.props.tableUsers);
    var eleFirst = (this.state.currentPage-1)*thePageSize;
    var eleLast  = eleFirst + thePageSize;
    this.setState( { 
      imagefilesSelected: this.props.mappedImagefiles.slice(eleFirst,eleLast),
      pageSize: thePageSize
    });
  }

  componentWillReceiveProps() {
    //console.log("...cwk999-989    src/components/Tuser/DatatableImagefile/index.js componentWillReceiveProps this.props.tableUsers=",this.props.tableUsers);
    this.setState( { 
      isLoaded: false,
    });
  }

  componentDidUpdate() {
    console.log(".....cwk999-977-01 ./src/components/Imagefile/DatatableImagefile/index.js componentDidUpdate this.state.isLoaded=",this.state.isLoaded);
    if ( this.state.isLoaded ) return;
    const quotient  = ~~(this.props.mappedImagefiles.length/thePageSize);
    const remainder = this.props.mappedImagefiles.length % thePageSize;   
    var thePageCount = quotient;
    if ( remainder >0 ) {
      thePageCount = thePageCount +1;
    }
    console.log(".....cwk999-977-02 ./src/components/Imagefile/DatatableImagefile/index.js componentDidUpdate this.props.imagefiles=",this.props.imagefiles);
    this.setState( { 
      isLoaded: true,
      pageCount: thePageCount,
      imagefilesSelected: this.props.mappedImagefiles.slice(0,thePageSize)
    });
  }

  /* Passing an additional parameter with to onChange event */
  onInputChange = idxUser => ( event) => {
    const { name, value} = event.target;
    var theList = this.state.theTableUsers;
    theList[idxUser][name]=value;
    this.setState({ theTableUsers: theList });  
  };

  showModal = (theImagefile) => {
    //console.log(".....cwk001-222 theUser=",theUser)
    this.setState({ 
      show: true ,
      imagefileCurrent: theImagefile
    });
  };

  hideModal = () => {
    //console.log(".....cwk991-777 Tuser.js hideModal");
    this.setState({ show: false });
  };

  // Sorting
  onSortChange = key => {
    //console.log(".....cwk777-100 key=",key);
    let ordered = this.state.imagefilesSelected.sort( (a, b) => (a[key] > b[key] ? 1 : -1) );
    //console.log(".....cwk777-200 this.state.imagefilesSelected=",this.state.imagefilesSelected);
    //console.log(".....cwk777-210 ordered=",ordered);
    this.setState({
      imagefilesSelected: ordered,
    })
  };

  // Pagination
  handlePageClick = (e) => {
    //console.log(".....cwk993-999 handlePageClick event=", e);
    const selectedPage = e.selected;
    const offset = selectedPage * pageSize;
    //console.log(".....cwk020-100 handlePageClick this.state.imagefilesSelected=", this.state.imagefilesSelected);
    //console.log(".....cwk993-100 handlePageClick selectedPage=",selectedPage +",offset=", offset, ",offset+this.state.pageSize=", offset+this.state.pageSize);

    this.setState({
        currentPage: selectedPage,
        offset: offset,
        imagefilesSelected: this.props.mappedImagefiles.slice(offset, offset+this.state.pageSize)
    }, () => {
        //console.log(".....cwk333-100 handlePageClick this.state.imagefilesSelected=", this.state.imagefilesSelected);
        //this.receivedData()
    });
  };  

  render() {
    return (
      <div className="table-wrapper">
        <table className="data-table">
          <thead>
            <tr>
              <th>id</th>
              <th>Photo</th>
              <th onClick={() => {  this.onSortChange("imagename"); }} >
                <span className="column-sort"> Image Name <img src={SortIcon} alt="Configuration Key" /> </span>
              </th>
              <th onClick={() => {  this.onSortChange("comment"); }}>
                <span className="column-sort">  Remark <img src={SortIcon} alt="Configuration Value" />  </span>
              </th>      
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.imagefilesSelected.length ? (
              this.state.imagefilesSelected.map(imagefile => (
                <tr key={imagefile.id}>
                  <td>{imagefile.id}</td>
                  <td width="40" height="40"><img src={process.env.REACT_APP_API_IMAGE_URL+"jamsmart/"+imagefile.imagename}  /></td>
                  <td>{imagefile.imagename}</td>
                  <td>{imagefile.comment}</td>
                  <td className="field-actions">
                    <button className="primary-btn"           onClick={() => { this.showModal(imagefile);     }}> Update </button>
                    <Modal
                      isOpen={this.state.show}
                      onRequestClose={this.toggleModal}
                      contentLabel="My Update dialog"
                      className="layout"
                    >
                      <div>Update Image Files</div>
                      <div >
                        <ModalUpdate onParentSubmit={this.hideModal} imagefileCurrent={this.state.imagefileCurrent}/>
                      </div>
                    </Modal>
                    <button className="field-actions__delete" onClick={() => { this.props.deleteRow(imagefile); }}> Delete </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">
                  <div className="no-record-message">No Record!</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={this.state.pageSize}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
        />          
      </div>
    );
  }
}

/*
  Wait for redux action to finish dispatching
*/
tableImagefile.propTypes = {
  imagefiles: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  return {
    mappedImagefiles: state.imageFile.reduxImagefiles,
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(tableImagefile);
