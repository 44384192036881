import * as actionTypes from './actionTypes';
import axios from '../../axios';


const apiURL = process.env.REACT_APP_REQRES_API;

export const loadingSetting = () => {
  return {
    type: actionTypes.SETTINGS_LOADING,
  }
};

export const querySettingSuccess = data => {
    return {
      type: actionTypes.SETTINGS_QUERY_SUCCESS,
      data
    }
};
  
export const insertSettingSuccess = data => {
  return {
    type: actionTypes.SETTINGS_INSERT_A_SETTING_SUCCESS,
    data
  }
};

export const deleteSettingSuccess = data => {
  return {
    type: actionTypes.SETTINGS_DELETE_A_SETTING_SUCCESS,
    data
  }
};

export const updateSettingSuccess = data => {
  return {
    type: actionTypes.SETTINGS_UPDATE_A_SETTING_SUCCESS,
    data
  }
};

export const asyncQuerySetting = () => {
  return dispatch => {
    dispatch(loadingSetting());
    axios.get(apiURL+"/getSettings/").then(({ data }) => {
          dispatch(querySettingSuccess(data));
        })
        .catch(err => {
            console.log("....cwk123-100 Setting action err=",err)
        });
  };
};

export const asyncInsertASetting = (params) => {
  return dispatch => {
    console.log(".....cwk666-100 src/store/actions/tuserActions.js asyncInsertASetting params=",params)
    axios.post(apiURL+"/postASetting/",{
      params: {
        confKey: params.confKey,
        confValue: params.confValue,
      }  
    }).then(({ data }) => {
          dispatch(insertSettingSuccess(data));
        })
        .catch(err => {
            console.log("....cwk123-300 Setting insert action err=",err)
        });
  };
};

export const asyncDeleteASetting = (params) => {
  return dispatch => {
    console.log(".....cwk666-100 src/store/actions/tuserActions.js asyncDeleteASetting params=",params)
    axios.delete(apiURL+"/delASetting/"+params.id, {
      params: {
        id: params.id
      }  
    }).then(({ data }) => {
          dispatch(deleteSettingSuccess(data));
        })
        .catch(err => {
            console.log("....cwk123-300 Setting delete action err=",err)
        });
  };
};

export const asyncUpdateASetting = (params) => {
  return dispatch => {
    console.log(".....cwk666-100 src/store/actions/tuserActions.js asyncUpdateASetting params=",params)
    const { id, confKey, confValue } = params;

    axios.put(apiURL+"/putASetting/", {
      params: {
        id: id,
        confKey: confKey,
        confValue: confValue,
      }  
    }).then(({ data }) => {
          dispatch(updateSettingSuccess(data));
        })
        .catch(err => {
            console.log("....cwk123-300 Setting update action err=",err)
        });
  };
};
