import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  tusers: [],
};

const setLoadingStatus = (state, action) => {
  return {
    ...state,
    isLoading: true
  }
}

const queryTuserSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    tusers: action.data
  }
}

const insertTuserSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    //tusers: action.data
  }
}

const deleteTuserSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    //tusers: action.data
  }
}

const updateTuserSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    //tusers: action.data
  }
}

const tuserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TUSERS_QUERY_SUCCESS:
      return queryTuserSuccess(state, action); 
    case actionTypes.TUSERS_LOADING:
      return setLoadingStatus(state, action); 
    case actionTypes.TUSERS_INSERT_A_TUSER_SUCCESS:
      return insertTuserSuccess(state, action); 
    case actionTypes.TUSERS_DELETE_A_TUSER_SUCCESS:
      return deleteTuserSuccess(state, action); 
    case actionTypes.TUSERS_UPDATE_A_TUSER_SUCCESS:
      return updateTuserSuccess(state, action); 
        
    default:
      return state;
  }
}

export default tuserReducer;