import React, {Component} from "react";
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import "./ModalUpdate.scss";

class ModalUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      setting: null,

      id: this.props.settingCurrent.id, 
      confKey: this.props.settingCurrent.confKey, 
      confValue: this.props.settingCurrent.confValue, 
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  onInputChange = event => {
    const { name, value } = event.target;
    //console.log(".....cwk761-100 ModalUpdate name=",name, ",value=",value);
    this.setState({ [name]: value });  
    this.props.settingCurrent[name]=value;
  };

  handleChange(event) {    
    this.setState({value: event.target.value});  
  }

  handleSubmit(event) {
    //console.log(".....cwk761-222 ModalUpdate handleSubmit this.props.settingCurrent=",this.props.settingCurrent);
    this.props.onAsyncUpdateASetting(this.props.settingCurrent);
    //alert('submitted: ' + this.state.value);
    event.preventDefault();
    this.props.onParentSubmit();
  }

  handleCancel(event) {
    //console.log(".....cwk761-222 ModalUpdate handleSubmit this.state=",this.state);
    //alert('submitted: ' + this.state.value);
    event.preventDefault();
    this.props.onParentSubmit();
  }

  componentDidUpdate() {
    //console.log(".....cwk001-199 ModalUpdate componentDidUpdate this.props.settingCurrent=",this.props.settingCurrent);
  }

  render() {
    //console.log("....cwk101-111 this.props=", this.props);
    //console.log(".....cwk001-199 ModalUpdate componentDidUpdate this.props.settingCurrent=",this.props.settingCurrent);
    return (
      <div className="layoutSetting">
        {/* <form onSubmit={this.handleSubmit}> */}
        <form >
            <div>
              <label>confKey: </label>
              <input type="text" name="confKey" value={this.props.settingCurrent.confKey} onChange={this.onInputChange} />
            </div>
            <div>
              <label>confValue: </label>
              <textarea style={{width: "400px"}} name="confValue" value={this.props.settingCurrent.confValue} onChange={this.onInputChange} />
            </div>
            <div className="form-group form-group--actions">
              <button className="primary-btn" onClick={this.handleSubmit}>Update</button>
              <button className="cancel-btn" onClick={this.handleSubmit}> Cancel </button>
            </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAsyncUpdateASetting: (setting) => dispatch(actions.asyncUpdateASetting(setting)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdate);
