import React from "react";
import Header from "../../components/Header";

const About = () => {
  return (
    <div>
      <h1>This is About Component</h1>
    </div>
  );
};

export default About;

