import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  reduxSettings: [],
};

const setLoadingStatus = (state, action) => {
  return {
    ...state,
    isLoading: true
  }
}

const querySettingSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    reduxSettings: action.data
  }
}

const insertSettingSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    //reduxSettings: action.data
  }
}

const deleteSettingSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    //reduxSettings: action.data
  }
}

const updateSettingSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    //reduxSettings: action.data
  }
}

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETTINGS_QUERY_SUCCESS:
      return querySettingSuccess(state, action); 
    case actionTypes.SETTINGS_LOADING:
      return setLoadingStatus(state, action); 
    case actionTypes.SETTINGS_INSERT_A_SETTING_SUCCESS:
      return insertSettingSuccess(state, action); 
    case actionTypes.SETTINGS_DELETE_A_SETTING_SUCCESS:
      return deleteSettingSuccess(state, action); 
    case actionTypes.SETTINGS_UPDATE_A_SETTING_SUCCESS:
      return updateSettingSuccess(state, action); 
        
    default:
      return state;
  }
}

export default settingReducer;