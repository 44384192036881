import React, {Component} from "react";
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import "./ModalUpdate.scss";

class ModalUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      product: null,

      id: this.props.productCurrent.id, 
      img: this.props.productCurrent.img, 
      title: this.props.productCurrent.title, 
      subtitle : this.props.productCurrent.subtitle, 
      description : this.props.productCurrent.description, 
      price : this.props.productCurrent.price, 
      inCart : this.props.productCurrent.inCart, 
      inWishlist : this.props.productCurrent.inWishlist, 
      amount : this.props.productCurrent.amount, 
      total : this.props.productCurrent.total, 
      size : this.props.productCurrent.size, 
      category : this.props.productCurrent.category, 
      gender	: this.props.productCurrent.gender,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  onInputChange = event => {
    const { name, value } = event.target;
    //console.log(".....cwk761-100 ModalUpdate name=",name, ",value=",value);
    this.setState({ [name]: value });  
    this.props.productCurrent[name]=value;
  };

  handleChange(event) {    
    this.setState({value: event.target.value});  
  }

  handleSubmit(event) {
    //console.log(".....cwk761-222 ModalUpdate handleSubmit this.props.productCurrent=",this.props.productCurrent);
    this.props.onAsyncUpdateAShoppingProduct(this.props.productCurrent);
    //alert('submitted: ' + this.state.value);
    event.preventDefault();
    this.props.onParentSubmit();
  }

  handleCancel(event) {
    //console.log(".....cwk761-222 ModalUpdate handleSubmit this.state=",this.state);
    //alert('submitted: ' + this.state.value);
    event.preventDefault();
    this.props.onParentSubmit();
  }

  componentDidUpdate() {
    //console.log(".....cwk001-199 ModalUpdate componentDidUpdate this.props.productCurrent=",this.props.productCurrent);
  }

  render() {
    //console.log("....cwk101-111 this.props=", this.props);
    //console.log(".....cwk001-199 ModalUpdate componentDidUpdate this.props.productCurrent=",this.props.productCurrent);
    return (
      <div className="layoutProduct">
        {/* <form onSubmit={this.handleSubmit}> */}
        <form >
            <div>
              <label for="name">Img: </label>
              <input style={{width: "400px"}} type="text" name="img" value={this.props.productCurrent.img} onChange={this.onInputChange} />
            </div>
            <div>
              <label for="title">Title: </label>
              <input style={{width: "400px"}} type="text" name="title" value={this.props.productCurrent.title} onChange={this.onInputChange} />
            </div>
            <div>                                                                                                                
              <label for="subtitle">subtitle:</label>                                                                            
              <input style={{width: "400px"}} type="text" name="subtitle" value={this.props.productCurrent.subtitle} onChange={this.onInputChange}/>      
            </div>                                                                                                               
            <div>                                                                                                                
              <label for="description">description:</label>                                                                      
              <textarea style={{width: "600px"}} name="description" value={this.props.productCurrent.description} onChange={this.onInputChange}/>
            </div>                                                                                                               
            <div>                                                                                                                
              <label for="price">price:</label>                                                                                  
              <input type="text" name="price" value={this.props.productCurrent.price} onChange={this.onInputChange}/>            
            </div>                                                                                                               
            <div>                                                                                                                
              <label for="size">size::</label>                                                                                   
              <input type="text" name="size" value={this.props.productCurrent.size} onChange={this.onInputChange}/>              
            </div>                                                                                                               
            <div>                                                                                                                
              <label for="category">category:</label>                                                                            
              <input type="text" name="category" value={this.props.productCurrent.category} onChange={this.onInputChange}/>      
            </div>                                                                                                               
            <div>                                                                                                                
              <label for="gender">gender:</label>                                                                                
              <input type="text" name="gender" value={this.props.productCurrent.gender} onChange={this.onInputChange}/>          
            </div>                                                                                                               
            <div className="form-group form-group--actions">
              <button className="primary-btn" onClick={this.handleSubmit}>Update</button>
              <button className="cancel-btn" onClick={this.handleSubmit}> Cancel </button>
            </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAsyncUpdateAShoppingProduct: (product) => dispatch(actions.asyncUpdateAShoppingProduct(product)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdate);
