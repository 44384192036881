import React, {Component} from "react";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import "./ModalImagefile.scss";

class ModalImagefile extends Component {
  constructor(props) {
    super(props);
    this.state = {
        file: null,
        id: null, 
        imagename: "", 
        comment: "", 
        shoppingsite: process.env.REACT_APP_SITENAME,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  onInputChange = event => {
    const { name, value } = event.target;
    //console.log(".....cwk761-100 ModalImagefile name=",name, ",value=",value);
    this.setState({ [name]: value });  
  };

  handleChange(event) {    
    console.log(".....cwk001-100 event.target.value=",event.target.value);
    const file = event.target.files[0]; // accesing file
    this.setState({file: file});  
  }

  handleSubmit(event) {
    console.log(".....cwk761-222 ModalImagefile handleSubmit this.state=",this.state);
    this.props.onAsyncInsertAnImagefile(this.state);
    this.props.onAsyncQueryImagefile();
    //alert('submitted: ' + this.state.value);
    event.preventDefault();
    this.props.onParentSubmit();
  }

  handleCancel(event) {
    console.log(".....cwk761-222 ModalImagefile handleSubmit this.state=",this.state);
    //alert('submitted: ' + this.state.value);
    event.preventDefault();
    this.props.onParentSubmit();
  }

  render() {
    //console.log("....cwk101-111 this.props=", this.props);
    return (
      <div className="layout">
        {/* <form onSubmit={this.handleSubmit}> */}
        <form >
            <div className="file-upload">
                {/* <input type="file" ref={el} onChange={handleChange} />                 */}
                <input type="file" ref={this.file} onChange={this.handleChange}/>                
{/*                 
                <div className="progessBar" style={{ width: progress }}>
                   {progress}
                </div>
 */}                
                <button onClick={this.handleSubmit} className="upbutton">Upload</button>
            </div>
            <div className="form-group">
              <label>Remark: </label>
              <input 
                type="text" 
                name="comment" 
                value={this.state.comment} 
                onChange={this.onInputChange} 
              />
            </div>
{/*                 
            <div className="form-group">
              <label>Last Name: </label>
              <input
                type="text"
                name="last_name"
                value={this.state.last_name}
                onChange={this.onInputChange}
              />
            </div>
            <div className="form-group">
              <label>E-Mail: </label>
              <input
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.onInputChange}
              />
            </div>
*/}                
            <div className="form-group form-group--actions">
              <button className="primary-btn" onClick={this.handleSubmit}>Upload</button>
              <button className="cancel-btn" onClick={this.handleSubmit}> Cancel </button>
            </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAsyncInsertAnImagefile: (parms) => dispatch(actions.asyncInsertAnImagefile(parms)), /* TBD */
    onAsyncQueryImagefile:    () => dispatch(actions.asyncQueryImagefile()), /* TBD */
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalImagefile);
