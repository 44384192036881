import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  reduxShoppingProducts: [],
};

const setLoadingShoppingProductStatus = (state, action) => {
  return {
    ...state,
    isLoading: true
  }
}

const queryShoppingProductSuccess = (state, action) => {

  return {
    ...state,
    isLoading: false,
    reduxShoppingProducts: action.data,
  }
}

const insertShoppingProductSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    //reduxShoppingProducts: action.data
  }
}

const deleteShoppingProductSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    //reduxShoppingProducts: theList
  }
}

const updateShoppingProductSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    //reduxShoppingProducts: action.data
  }
}

const ShoppingProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOPPINGPRODUCTS_QUERY_SUCCESS:
      return queryShoppingProductSuccess(state, action); 
    case actionTypes.SHOPPINGPRODUCTS_LOADING:
      return setLoadingShoppingProductStatus(state, action); 
    case actionTypes.SHOPPINGPRODUCTS_INSERT_A_PRODUCT_SUCCESS:
      return insertShoppingProductSuccess(state, action); 
    case actionTypes.SHOPPINGPRODUCTS_DELETE_A_PRODUCT_SUCCESS:
      return deleteShoppingProductSuccess(state, action); 
    case actionTypes.SHOPPINGPRODUCTS_UPDATE_A_PRODUCT_SUCCESS:
      return updateShoppingProductSuccess(state, action); 
        
    default:
      return state;
  }
}

export default ShoppingProductReducer;