import * as actionTypes from './actionTypes';
import axios from '../../axios';


const apiURL = process.env.REACT_APP_REQRES_API;

export const loadingShoppingProduct = () => {
  return {
    type: actionTypes.SHOPPINGPRODUCTS_LOADING,
  }
};

export const queryShoppingProductSuccess = data => {
    return {
      type: actionTypes.SHOPPINGPRODUCTS_QUERY_SUCCESS,
      data
    }
};
  
export const insertShoppingProductSuccess = data => {
  return {
    type: actionTypes.SHOPPINGPRODUCTS_INSERT_A_PRODUCT_SUCCESS,
    data
  }
};

export const deleteShoppingProductSuccess = data => {
  return {
    type: actionTypes.SHOPPINGPRODUCTS_DELETE_A_PRODUCT_SUCCESS,
    data
  }
};

export const updateShoppingProductSuccess = data => {
  return {
    type: actionTypes.SHOPPINGPRODUCTS_UPDATE_A_PRODUCT_SUCCESS,
    data
  }
};

export const asyncQueryShoppingProduct = () => {
  return dispatch => {
    dispatch(loadingShoppingProduct());
    axios.get(apiURL+"/getShoppingProducts/").then(({ data }) => {
          dispatch(queryShoppingProductSuccess(data));
        })
        .catch(err => {
            console.log("....cwk123-100 ShoppingProduct action err=",err)
        });
  };
};

export const asyncInsertAShoppingProduct = (params) => {
    return dispatch => {
        console.log(".....cwk666-100 src/store/actions/tuserActions.js asyncInsertASetting params=",params)
        axios.post(apiURL+"/postAShoppingProduct/",{
          params: {
            img 	    :	params.img,
            title 	    :	params.title,
            subtitle 	:	params.subtitle,
            description :	params.description,
            price 	    :	params.price,
            inCart 	    :	params.inCart,
            inWishlist 	:	params.inWishlist,
            amount 	    :	params.amount,
            total 	    :	params.total,
            size 	    :	params.size,
            category 	:	params.category,
            gender 	    :	params.gender,
          }  
        }).then(({ data }) => {
              dispatch(insertShoppingProductSuccess(data));
            })
            .catch(err => {
                console.log("....cwk123-300 Setting insert action err=",err)
            });
      };
};

export const asyncDeleteAShoppingProduct = (params) => {
  return dispatch => {
    console.log(".....cwk666-100 src/store/actions/tuserActions.js asyncDeleteAShoppingProduct params=",params)
    axios.delete(apiURL+"/delAShoppingProduct/"+params.id, {
      params: {
        id: params.id
      }  
    }).then(({ data }) => {
          dispatch(deleteShoppingProductSuccess(data));
        })
        .catch(err => {
            console.log("....cwk123-300 ShoppingProduct delete action err=",err)
        });
  };
};

export const asyncUpdateAShoppingProduct = (params) => {
  return dispatch => {
    console.log(".....cwk666-101 src/store/actions/tuserActions.js asyncUpdateAShoppingProduct params=",params)
    //const { id, imagename, comment } = params;

    axios.put(apiURL+"/putAShoppingProduct/", {
      params: {
        img 	    :	params.img,
        title 	    :	params.title,
        subtitle 	:	params.subtitle,
        description :	params.description,
        price 	    :	params.price,
        inCart 	    :	params.inCart,
        inWishlist 	:	params.inWishlist,
        amount 	    :	params.amount,
        total 	    :	params.total,
        size 	    :	params.size,
        category 	:	params.category,
        gender 	    :	params.gender,
      }  
    }).then(({ data }) => {
          dispatch(updateShoppingProductSuccess(data));
        })
        .catch(err => {
            console.log("....cwk123-301 ShoppingProduct update action err=",err)
        });
  };
};
