import React, {Component} from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import "./style.scss";
import Modal from "react-modal";
import ModalUpdate from './ModalUpdate';
import ReactPaginate from 'react-paginate';

// Images
import PlaceholderImg from "../img/placeholder-user.jpg";
import SortIcon from "../img/sort-icon.png";

const pageSize = parseInt(process.env.REACT_APP_TABLE_PAGESIZE);   /* env variable */
const thePageSize = parseInt(process.env.REACT_APP_TABLE_PAGESIZE);

class tableProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded: false,
        productCurrent: [],
        productsSelected: [],
        currentPage: 0,
        offset: 0,
        pageCount: 0,
        pageSize:0,
      };
      
    this.onInputChange = this.onInputChange.bind(this);
    this.onSortChange  = this.onSortChange.bind(this);
    this.handlePageClick  = this.handlePageClick.bind(this);
    this.showModal     = this.showModal.bind(this);
    this.hideModal     = this.hideModal.bind(this);
  }

  componentDidMount() {
    //console.log(".....cwk999-991 src/components/Tuser/DatatableProduct/index.js componentDidMount this.props.tableUsers=",this.props.tableUsers);
    var eleFirst = (this.state.currentPage-1)*thePageSize;
    var eleLast  = eleFirst + thePageSize;
    this.setState( { 
      productsSelected: this.props.mappedShoppingProducts.slice(eleFirst,eleLast),
      pageSize: thePageSize
    });
  }

  componentWillReceiveProps() {
    //console.log("...cwk999-989    src/components/Tuser/DatatableProduct/index.js componentWillReceiveProps this.props.tableUsers=",this.props.tableUsers);
    this.setState( { 
      isLoaded: false,
    });
  }

  componentDidUpdate() {
    //console.log(".....cwk999-993    src/components/Tuser/DatatableProduct/index.js componentDidUpdate this.props.tableUsers=",this.props.tableUsers);
    //console.log(".....cwk999-997-03 src/components/Tuser/DatatableProduct/index.js componentDidUpdate this.props.products=",this.props.products);
    if ( this.state.isLoaded ) return;
    const quotient  = ~~(this.props.mappedShoppingProducts.length/thePageSize);
    const remainder = this.props.mappedShoppingProducts.length % thePageSize;   
    var thePageCount = quotient;
    if ( remainder >0 ) {
      thePageCount = thePageCount +1;
    }
    //console.log(".....cwk020-993-02 Tuser componentDidUpdate() this.props.products=",this.props.products);
    this.setState( { 
      isLoaded: true,
      pageCount: thePageCount,
      productsSelected: this.props.mappedShoppingProducts.slice(0,thePageSize)
    });
  }

  /* Passing an additional parameter with to onChange event */
  onInputChange = idxUser => ( event) => {
    const { name, value} = event.target;
    var theList = this.state.theTableUsers;
    theList[idxUser][name]=value;
    this.setState({ theTableUsers: theList });  
  };

  showModal = (theProduct) => {
    //console.log(".....cwk001-222 theUser=",theUser)
    this.setState({ 
      show: true ,
      productCurrent: theProduct
    });
  };

  hideModal = () => {
    //console.log(".....cwk991-777 Tuser.js hideModal");
    this.setState({ show: false });
  };

  // Sorting
  onSortChange = key => {
    //console.log(".....cwk777-100 key=",key);
    let ordered = this.state.productsSelected.sort( (a, b) => (a[key] > b[key] ? 1 : -1) );
    //console.log(".....cwk777-200 this.state.productsSelected=",this.state.productsSelected);
    //console.log(".....cwk777-210 ordered=",ordered);
    this.setState({
      productsSelected: ordered,
    })
  };

  // Pagination
  handlePageClick = (e) => {
    //console.log(".....cwk993-999 handlePageClick event=", e);
    const selectedPage = e.selected;
    const offset = selectedPage * pageSize;
    //console.log(".....cwk020-100 handlePageClick this.state.productsSelected=", this.state.productsSelected);
    //console.log(".....cwk993-100 handlePageClick selectedPage=",selectedPage +",offset=", offset, ",offset+this.state.pageSize=", offset+this.state.pageSize);

    this.setState({
        currentPage: selectedPage,
        offset: offset,
        productsSelected: this.props.mappedShoppingProducts.slice(offset, offset+this.state.pageSize)
    }, () => {
        //console.log(".....cwk333-100 handlePageClick this.state.productsSelected=", this.state.productsSelected);
        //this.receivedData()
    });
  };  

  render() {
    return (
      <div className="table-wrapper">
        <table className="data-table">
          <thead>
            <tr>
              <th>Id</th>
              <th onClick={() => {  this.onSortChange("img"); }} >
                <span className="column-sort"> img <img src={SortIcon} alt="Configuration Key" /> </span>
              </th>
              <th onClick={() => {  this.onSortChange("title"); }}>
                <span className="column-sort">  title <img src={SortIcon} alt="Configuration Value" />  </span>
              </th>      
              <th>subtitle</th>
              <th>description</th>
              <th>size</th>
              <th>category</th>
              <th>gender</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.productsSelected.length ? (
              this.state.productsSelected.map(product => (
                <tr key={product.id}>
                  <td>{product.id}</td>
                  <td>{product.img}</td>
                  <td>{product.title}</td>
                  <td>{product.subtitle}</td>
                  <td>{product.description}</td>
                  <td>{product.size}</td>
                  <td>{product.category}</td>
                  <td>{product.gender}</td>
                  <td className="field-actions">
                    <button className="primary-btn"           onClick={() => { this.showModal(product);     }}> Update </button>
                    <Modal
                      isOpen={this.state.show}
                      onRequestClose={this.toggleModal}
                      contentLabel="My Update dialog"
                      className="layout"
                    >
                      <div>Update a product</div>
                      <div >
                        <ModalUpdate onParentSubmit={this.hideModal} productCurrent={this.state.productCurrent}/>
                      </div>
                    </Modal>
                    <button className="field-actions__delete" onClick={() => { this.props.deleteRow(product); }}> Delete </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">
                  <div className="no-record-message">No Record!</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={this.state.pageSize}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
        />          
      </div>
    );
  }
}

/*
  Wait for redux action to finish dispatching
*/
tableProduct.propTypes = {
  products: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  return {
    mappedShoppingProducts: state.product.reduxShoppingProducts,
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(tableProduct);
